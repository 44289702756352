$env: production;
@import "@/styles/_component-base.styles.scss";

.productValueProps {
    background-color: color(contrast, 2);
}

.productValuePropsWrap {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include breakpoint(md) {
        display: grid;
        grid-auto-rows: 50%;
        grid-gap: spacer(base);
        grid-template-columns: auto auto;

        &--graphiteMemoryFoamTopper {
            grid-gap: spacer(base);
        }
    }

    @include breakpoint(xl) {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
}

.productValuePropsItem {
    break-inside: avoid;
    margin-bottom: spacer(base);

    @include breakpoint(lg) {
        flex-shrink: 0;
        margin-bottom: spacer(md);
    }

    @include breakpoint(xl) {
        flex-shrink: 0;
        max-width: 280px;
    }
}

.productValuePropsItem0 {
    grid-column: 1;
    grid-row: 1;
}
.productValuePropsItem1 {
    grid-column: 2;
    grid-row: 1;
}
.productValuePropsItem2 {
    grid-column: 1;
    grid-row: 2;
}
.productValuePropsItem3 {
    grid-column: 2;
    grid-row: 2;
}

.productValuePropsMainTitle {
    color: color(nav, active);
    line-height: 40px;
}

.productValuePropsIcon {
    align-self: center;
    flex: 0 0 auto;
    height: 50px;
    margin-right: spacer(base);
    stroke: color(action, base);
    width: 50px;
}

.productValuePropsTopper {
    overflow: visible;
}
