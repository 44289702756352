$env: production;
@import "@/styles/_component-base.styles.scss";

.productValuePropCard {
    break-inside: avoid;
    margin-bottom: spacer(base);
    
    // Using the element rather than the class because not all elements get a class applied
    & li {
        margin-bottom: spacer(base);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.productValuePropCardContent {
    background-color: color(state, base);
    background: linear-gradient(
            180deg,
            color(contrast, white) 3.18%,
            #fbfaf8 100%
        ),
        color(contrast, white);
    border-radius: 5px;
    box-shadow: 5px 5px 0px rgba(193, 163, 139, 0.2);
    padding: spacer(lg);

    @include breakpoint(md) {
        height: 100%;
    }
}
        
.productValuePropCardContentHeading {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: spacer(sm);
    align-items: center;

    [data-style-svgtype] {
        flex: 0 0 auto;
        height: 50px;
        margin-right: spacer(base);
        color: color(action, base);
        width: 50px;
    }
}
.productValuePropCardContentHeadingTitle {
    align-self: center;
    display: flex;
    font-size: set-fontSize(20);
    font-weight: 400;
    line-height: 25px;
    text-align: left;
}
        
.productValuePropCardContentHeadingDescription {
    p {
        font-size: set-fontSize(16);
        font-weight: 300;
        line-height: 26px;
        text-align: left;
    }
}

.productValuePropCardList {
    list-style-type: disc;
    margin-left: spacer(base);
}
