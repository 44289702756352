$env: production;
@import "@/styles/_component-base.styles.scss";

.supportSection {
    background-color: color(action, darkHover);

    &Heading {
        font-weight: 400;
        color: color(text, headingDark);
        font-size: set-fontSize(24);
        padding-bottom: spacer(lg);

        @include breakpoint(md) {
            font-size: set-fontSize(28);
        }

        @include breakpoint(lg) {
            font-size: set-fontSize(32);
        }
    }

    &Row {
        gap: spacer(lg);

        @include breakpoint(md) {
            gap: unset;
        }
    }
}

.valuePropColumn {
    @include breakpoint(md) {
        padding-left: spacer(xs);
        padding-right: spacer(xs);

        &:first-child {
            padding-left: spacer(base);
        }

        &:last-child {
            padding-right: spacer(base);
        }
    }
}

.valuePropCard {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    min-height: 140px;
    background: linear-gradient(180deg,
            color(contrast, white) 3.18%,
            #fbfaf8 100%),
        color(contrast, white);

    box-shadow: 5px 5px 0px rgba(193, 163, 139, 0.2);
    padding: spacer(lg);

    &:hover {
        cursor: pointer;
        box-shadow: none;
        background: color(state, base);
    }

    @include breakpoint(md) {
        min-height: 180px;
    }
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: spacer(base);

    svg {
        width: 40px;
        height: 40px;
        color: color(darkContrast, 1);

        @include breakpoint(md) {
            width: 56px;
            height: 56px;
        }
    }
}

.contentHead {
    font-family: $fontFamily-serif;
    font-size: set-fontSize(16);
    line-height: normal;
    margin-bottom: 0;

    @include breakpoint(md) {
        font-size: set-fontSize(20);
    }

    @media only screen and (min-width: 2560px) {
        font-size: set-fontSize(24);
    }
}

.subheading {
    font-family: $fontFamily-sans-serif;
    font-size: set-fontSize(14);
    padding-top: spacer(xs);
    font-weight: 300;

    @include breakpoint(md) {
        font-size: set-fontSize(16);
    }
}