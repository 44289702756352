$env: production;
@import '@/styles/_component-base.styles.scss';

.contentContainer {
    @include breakpoint(sm, max) {
        max-width: 330px;
        margin: auto;
    }

    @include breakpoint(md) {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.contentContainer--paddingLeft {
    @include breakpoint(md) {
        padding-left: spacer(base);
    }

    @include breakpoint(lg) {
        padding-left: spacer(7xl);
    }
}

.contentContainer--paddingRight {
    @include breakpoint(md) {
        padding-right: spacer(base);
    }

    @include breakpoint(lg) {
        padding-right: spacer(7xl);
    }
}

.title {
    margin-bottom: spacer(lg);
    color: color(text, base);

    @include breakpoint(md) {
        margin-bottom: spacer(md);
    }
}

.list {
    list-style-type: disc;
    padding-left: spacer(base);
}

.image {
    padding: 0 spacer(base);
}

.dotsRight {
    @include breakpoint(lg) {
        padding-left: spacer(4xl);
    }

    @include breakpoint(md) {
        padding-left: spacer(5xl);
        justify-content: flex-start;
    }

    margin-top: spacer(base);
}

.dotsLeft {
    @include breakpoint(lg) {
        padding-right: spacer(4xl);
    }

    @include breakpoint(md) {
        padding-right: spacer(5xl);
        justify-content: flex-end;
    }

    margin-top: spacer(base);
}

.imageContainer {
    margin-bottom: spacer(lg);

    @include breakpoint(md) {
        margin-bottom: 0;
    }
}